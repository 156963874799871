import { AffiliateNetwork } from "../domain/affiliate-lead/affiliate-lead";

const compadoNetwork: AffiliateNetwork = {
  name: "compado",
  urlParameters: ["compadoid"],
};

const tagNetwork: AffiliateNetwork = {
  name: "tag",
  urlParameters: ["_ef_transaction_id"],
};

const tradedoublerNetwork: AffiliateNetwork = {
  name: "tradedoubler",
  urlParameters: ["tduid"],
};

const fcmediaNetwork: AffiliateNetwork = {
  name: "fcmedia",
  urlParameters: ["clickId"],
};

const addrevenueIoNetwork: AffiliateNetwork = {
  name: "addrevenue",
  urlParameters: ["clickId", "channelId", "clickRef"],
};

export const networkConfigs: AffiliateNetwork[] = [
  compadoNetwork,
  tagNetwork,
  tradedoublerNetwork,
  fcmediaNetwork,
  addrevenueIoNetwork,
];
