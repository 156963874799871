import { create } from "zustand";
import { devtools } from "zustand/middleware";

import { createResultViewModel, ResultViewModel } from "../../domain/view-models/result-view-model";
import { createResultAdapter } from "../adapters/";

type ResultState = {
  result: ResultViewModel | null;
  setResult: (result: ResultViewModel) => void;
  clearResult: () => void;
  fetchResult: (resultUuid: string) => Promise<ResultViewModel | null>;
};

export const useResultStore = create<ResultState>()(
  devtools(
    (set) => {
      const adapter = createResultAdapter();

      return {
        result: null,
        setResult: (result) => set({ result }, false, "setResult"),
        clearResult: () => set({ result: null }, false, "clearResult"),
        fetchResult: async (resultUuid) => {
          const dto = await adapter.fetchResult(resultUuid);
          if (!dto) return null;

          const viewModel = createResultViewModel(dto);
          set({ result: viewModel }, false, "fetchResult");

          return viewModel;
        },
      };
    },
    {
      name: "Result Store",
      enabled: process.env.NODE_ENV === "development",
    }
  )
);
