import type { Locale } from "../view-models";

export const AVAILABLE_LOCALES: Record<string, Locale> = {
  es: {
    code: "es",
    name: "Spanish",
    nativeName: "Español",
  },
  de: {
    code: "de",
    name: "German",
    nativeName: "Deutsch",
  },
  fr: {
    code: "fr",
    name: "French",
    nativeName: "Français",
  },
  it: {
    code: "it",
    name: "Italian",
    nativeName: "Italiano",
  },
  pt: {
    code: "pt",
    name: "Portuguese",
    nativeName: "Português",
  },
  nl_NL: {
    code: "nl-NL",
    name: "Dutch",
    nativeName: "Nederlands",
  },
  pl_PL: {
    code: "pl-PL",
    name: "Polish",
    nativeName: "Polski",
  },
  de_AT: {
    code: "de-AT",
    name: "Austrian German",
    nativeName: "Österreichisches Deutsch",
  },
  nb_NO: {
    code: "nb-NO",
    name: "Norwegian",
    nativeName: "Norsk",
  },
  fi_FI: {
    code: "fi-FI",
    name: "Finnish",
    nativeName: "Suomi",
  },
  en_IE: {
    code: "en-IE",
    name: "Irish English",
    nativeName: "English",
  },
  el_GR: {
    code: "el-GR",
    name: "Greek",
    nativeName: "Ελληνικά",
  },
  hu_HU: {
    code: "hu-HU",
    name: "Hungarian",
    nativeName: "Magyar",
  },
  ro_RO: {
    code: "ro-RO",
    name: "Romanian",
    nativeName: "Română",
  },
  cs_CZ: {
    code: "cs-CZ",
    name: "Czech",
    nativeName: "Čeština",
  },
};
export const DEFAULT_LOCALE = AVAILABLE_LOCALES.es;
