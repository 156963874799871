import { EXPERIMENT_IDS } from "../constants/experiments";
import { Country } from "./country-view-model";

type Variant = {
  id: string;
  name: string;
  weight?: number;
};

export type Experiment = {
  id: string;
  name: string;
  variants: Variant[];
  countries?: string[];
};

export type ExperimentViewModel = {
  id: string;
  activeVariant: Variant;
};

export type ExperimentId = (typeof EXPERIMENT_IDS)[keyof typeof EXPERIMENT_IDS];

export const isExperimentEligible = (experiment: Experiment, country?: Country): boolean => {
  if (!experiment.countries || experiment.countries.length === 0) return true;
  if (!country) return false;

  return experiment.countries.includes(country.iso);
};

export const selectVariant = (experiment: Experiment, randomValue: number): Variant => {
  let cumulativeWeight = 0;

  if (experiment.variants.every((v) => typeof v.weight === "number")) {
    for (const variant of experiment.variants) {
      cumulativeWeight += variant.weight ?? 0;
      if (randomValue < cumulativeWeight) return variant;
    }
  }

  const index = Math.floor(randomValue * experiment.variants.length);

  return experiment.variants[index];
};

export const hashString = (str: string): number => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash;
  }

  return Math.abs(hash) / 2147483647;
};

export const createExperimentViewModel = (
  experiment: Experiment,
  activeVariantId: string
): ExperimentViewModel => {
  const activeVariant =
    experiment.variants.find((v) => v.id === activeVariantId) ?? experiment.variants[0];

  return {
    id: experiment.id,
    activeVariant,
  };
};
