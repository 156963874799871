import { AVAILABLE_COUNTRIES, AVAILABLE_LOCALES } from "../../domain/constants";
import { PartialWindow } from "../../types";
import {
  getLeadsNewCountryParam,
  getResultUuidFromUrl,
  getUrlLocale,
  isResultUrl,
} from "../../utils/browser-features";

export type DetectionResult = {
  isResultUrl: boolean;
  resultUuid: string | null;
  countryIsoParam: string | null;
  urlLocale: string | null;
};

const isValidCountryIso = (countryIso: string): boolean => {
  return Boolean(Object.prototype.hasOwnProperty.call(AVAILABLE_COUNTRIES, countryIso));
};

const isValidLocale = (localeCode: string): boolean => {
  return Object.values(AVAILABLE_LOCALES).some(
    (locale) => locale.code.toLowerCase() === localeCode.toLowerCase()
  );
};

export const onClientEntryAdapter = (windowObj = window as PartialWindow): DetectionResult => {
  const resultUrlDetected = isResultUrl(windowObj);
  const resultUuid = resultUrlDetected ? getResultUuidFromUrl(windowObj) : null;

  let countryIsoParam: string | null = null;
  const countryParam = getLeadsNewCountryParam(windowObj);
  if (countryParam) {
    const normalizedCountryIso = countryParam.toUpperCase();
    if (isValidCountryIso(normalizedCountryIso)) {
      countryIsoParam = normalizedCountryIso;
    }
  }

  let urlLocale: string | null = null;
  const detectedLocale = getUrlLocale(windowObj);
  if (detectedLocale && isValidLocale(detectedLocale)) {
    urlLocale = detectedLocale;
  }

  return {
    isResultUrl: resultUrlDetected,
    resultUuid,
    countryIsoParam,
    urlLocale,
  };
};
