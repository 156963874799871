import { onClientEntryAdapter } from "../../data/adapters";
import { useResultStore } from "../../data/repositories";
import { useCountryStore } from "../../data/repositories/country-detection-repository";
import { AVAILABLE_COUNTRIES, DEFAULT_LOCALE } from "../../domain/constants";
import {
  CountryDetectionResponse,
  CountrySource,
  CountrySourceType,
  createCountryViewModel,
} from "../../domain/view-models";
import { PartialWindow } from "../../types";
import { initializeExperiments } from "./experiment-service";

const initializeCountryAndExperiments = (
  countryIso: string,
  source: CountrySourceType,
  leadKbKey?: string
) => {
  const countryStore = useCountryStore.getState();
  const country = AVAILABLE_COUNTRIES[countryIso];

  const detection: CountryDetectionResponse = {
    locale: country.language.code,
    source,
  };

  const countryViewModel = createCountryViewModel(detection);

  countryStore.setCountryState(countryViewModel.country, countryViewModel.source);
  initializeExperiments({ country: countryViewModel.country, leadKbKey });

  return true;
};

export const onClientEntryService = async (windowObj = window as PartialWindow): Promise<void> => {
  const { isResultUrl, resultUuid, countryIsoParam, urlLocale } = onClientEntryAdapter(windowObj);

  if (isResultUrl && resultUuid) {
    const fetchResult = useResultStore.getState().fetchResult;
    const result = await fetchResult(resultUuid);

    if (result?.country && result.petParent.kbKey) {
      initializeCountryAndExperiments(result.country, CountrySource.RESULT, result.petParent.kbKey);

      return;
    }
  }

  if (countryIsoParam) {
    initializeCountryAndExperiments(countryIsoParam, CountrySource.URL);

    return;
  }

  if (urlLocale) {
    const matchingCountry = Object.entries(AVAILABLE_COUNTRIES).find(
      ([, country]) => country.language.code.toLowerCase() === urlLocale.toLowerCase()
    );

    if (matchingCountry) {
      const [countryIso] = matchingCountry;
      initializeCountryAndExperiments(countryIso, CountrySource.URL);

      return;
    }
  }

  const defaultCountry = Object.entries(AVAILABLE_COUNTRIES).find(
    ([, country]) => country.language.code === DEFAULT_LOCALE.code
  );

  if (defaultCountry) {
    const [defaultCountryIso] = defaultCountry;
    initializeCountryAndExperiments(defaultCountryIso, CountrySource.DEFAULT);
  }
};
