import { fetchApiJson } from "../../../../utils/request";
import { ResultDTO } from "../../domain/view-models/result-view-model";

function resultUrl(resultUuid: string): string {
  if (!process.env.GATSBY_API_BASE) {
    throw new Error("Missing GATSBY_API_BASE environment variable");
  }

  return `${process.env.GATSBY_API_BASE}/results/${resultUuid}/`;
}

type ResultAdapter = {
  fetchResult: (resultUuid: string) => Promise<ResultDTO | null>;
};

export const createResultAdapter = (): ResultAdapter => {
  const fetchResult = async (resultUuid: string): Promise<ResultDTO | null> => {
    return fetchApiJson<ResultDTO>(`${resultUrl(resultUuid)}?include=quotes`);
  };

  return {
    fetchResult,
  };
};
