import { create } from "zustand";
import { devtools } from "zustand/middleware";

import { Country, CountrySourceType } from "../../domain/view-models";

type CountryState = {
  country: Country | null;
  source: CountrySourceType | null;
  setCountryState: (country: Country, source: CountrySourceType) => void;
};

export const useCountryStore = create<CountryState>()(
  devtools(
    (set) => ({
      country: null,
      source: null,
      setCountryState: (country, source) => set({ country, source }, false, `setCountry/${source}`),
    }),
    {
      name: "Country Store",
      enabled: process.env.NODE_ENV === "development",
    }
  )
);
