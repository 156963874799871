import { createExperimentStorageAdapter } from "../func-core/core/data/adapters/experiment-storage-adapter";
import { getWindow } from "./browser-features";
import { getExperimentsList } from "./experiments";

export enum Events {
  ACCESS_VIEWED_BROWSER = "Access Viewed",
  APPLE_PAY_VIEWED_BROWSER = "Apple Pay Viewed",
  ADDRESS_VIEWED_BROWSER = "Address Viewed",
  BINDING_VIEWED_BROWSER = "Binding Viewed",
  BLOCK_COLLAPSED = "Block Collapsed",
  BLOCK_EXPANDED = "Block Expanded",
  CHECKOUT_VIEWED_BROWSER = "Checkout Viewed",
  CHECKOUT_SUCCESS_VIEWED_BROWSER = "Checkout Viewed",
  CHIP_VIEWED_BROWSER = "Chip Viewed",
  CLICKED_CTA = "Clicked CTA",
  CLICKED_EXTERNAL_LINK = "Clicked external link",
  CLICKED_GO_TO_PAYMENT = "Clicked go to payment cta",
  DATE_SELECTED = "Date selected",
  DETAILS_VIEWED_BROWSER = "Details Viewed",
  ELEMENT_SHOWN = "Element shown",
  ERROR_OCCURRED = "Error Occurred",
  FORM_ANSWERED = "Form answered",
  HAS_CHIP_BROWSER = "Has chip Viewed",
  ITEM_SELECTED = "Item Selected",
  MISSING_ANSWER = "Missing Answer",
  DIALOG_CLOSED = "Dialog Closed",
  DIALOG_OPENED = "Dialog Opened",
  NO_CHIP_VIEWED_BROWSER = "No chip Viewed",
  ONBOARDING_VIEWED_BROWSER = "Onboarding Viewed",
  PET_FAMILY_VIEWED_BROWSER = "Pet family Viewed",
  PET_HAS_FAMILY = "Pet has family",
  PET_INFO_EDITED = "Pet info edited",
  PET_PARENT_VIEWED_BROWSER = "Pet parent Viewed",
  PHONE_SUBMITTED = "Phone Submitted",
  PRODUCT_BOUGHT = "Product Bought",
  QUOTES_DISPLAYED_BROWSER = "Quotes Displayed",
  REDIRECT_TO_TRUSTPILOT = "Redirect to Trustpilot",
  SECTION_VIEWED_BROWSER = "Section Viewed",
  SMALL_PRINT_PRE_EXISTING_CONDITIONS_VIEWED_BROWSER = "Small print - Pre-existing conditions Viewed",
  SMALL_PRINT_START_VIEWED_BROWSER = "Small print - Start Viewed",
  SMALL_PRINT_WAITING_PERIOD_VIEWED_BROWSER = "Small print - Waiting Period Viewed",
  SHIPPING_VIEWED_BROWSER = "Shipping Viewed",
  START_TYPING_ON_FIELD = "Start typing on field",
  SWITCH_TO_MONTHLY_ERROR_VIEWED_BROWSER = "Switch to monthly error Viewed",
  SWITCH_TO_MONTHLY_LOADER_VIEWED_BROWSER = "Switch to monthly loader Viewed",
  SWITCH_TO_MONTHLY_SUCCESS_VIEWED_BROWSER = "Switch to monthly success Viewed",
  SWITCH_TO_MONTHLY_VIEWED_BROWSER = "Switch to monthly Viewed",
  TAPPED_ON_CANCEL_ACTION = "Tapped on cancel action",
  TAPPED_ON_EDIT = "Tapped on edit",
  TAPPED_ON_EDIT_LINK = "Tapped on edit link",
  TAPPED_ON_SAVE_ACTION = "Tapped on save action",
  TURNED_OFF = "Turned Off",
  TURNED_ON = "Turned On",
  UNCOVERED_PET = "Uncovered Pet",
  WELCOME_VIEWED_BROWSER = "Welcome Viewed",
}

export function track(event: string, properties = {}): void {
  const w = getWindow();
  const experimentsList = getExperimentsToTrack();

  if (!w?.analytics?.track) {
    return;
  }

  w.analytics.track(event, { page: w.location.href, ...properties, ...{ experimentsList } });
}

export async function trackWithPromise(event: string, properties = {}): Promise<void> {
  return new Promise((resolve, reject) => {
    const w = getWindow();
    const experimentsList = getExperimentsToTrack();

    if (!w?.analytics?.track) {
      resolve();

      return;
    }

    try {
      w.analytics.track(
        event,
        { page: w.location.href, ...properties, ...{ experimentsList } },
        resolve
      );
    } catch (error) {
      reject(error);
    }
  });
}

export function getAnonymousId(): string {
  const w = getWindow();
  if (!w?.document.cookie) {
    return "";
  }

  const cookieName = "ajs_anonymous_id";

  return w.document.cookie.match(`(^|;)\\s*${cookieName}\\s*=\\s*([^;]+)`)?.pop() ?? "";
}

export function trackChoice(e: { target?: HTMLInputElement }, properties = {}): void {
  const { target } = e;
  const fullProperties = {
    target: target?.type === "checkbox" ? target.checked : target?.value,
    ...properties,
  };
  const eventName = target?.checked ? Events.TURNED_ON : Events.TURNED_OFF;

  track(eventName, fullProperties);
}

export function trackSectionVisibility(
  sectionName: string,
  secondsVisible: number,
  properties = {}
): void {
  const fullProperties = { section: sectionName, seconds: secondsVisible, ...properties };

  track(Events.SECTION_VIEWED_BROWSER, fullProperties);
}

export function trackLink(element: HTMLAnchorElement, eventName: string, properties = {}): void {
  const w = getWindow();
  const experimentsList = getExperimentsToTrack();

  if (!w?.analytics?.trackLink) {
    return;
  }

  w.analytics.trackLink(element, eventName, {
    page: w.location.href,
    ...properties,
    ...{ experimentsList },
  });
}

export function identify(userId: string, traits: Record<string, unknown>): void {
  const w = getWindow();
  if (!w?.analytics?.identify) {
    return;
  }

  w.analytics.identify(userId, traits);
}

const formValueHasChanged = (oldValue: string | undefined, newValue: string | undefined): boolean =>
  oldValue !== newValue;

export const trackFormChanges = <T>(
  eventSender: string,
  newValues: T,
  oldValues: Record<string, string>,
  fieldsToTrack: Array<keyof T>
): void => {
  const trackedEventData: Record<string, any> = {
    eventSender,
    newValues,
    oldValues,
  };

  fieldsToTrack.forEach((field) => {
    trackedEventData[`${String(field)}Changed`] = formValueHasChanged(
      oldValues[String(field)],
      newValues[field] as unknown as string
    );
  });

  track(Events.FORM_ANSWERED, trackedEventData);
};

export const getExperimentsToTrack = (): Record<string, string> => {
  try {
    const adapter = createExperimentStorageAdapter();
    const assignments = adapter.getAllAssignments();
    const experimentsList: Record<string, string> = getExperimentsList();

    const experimentAssignments = Object.values(assignments).reduce((acc, assignment) => {
      acc[assignment.experimentId] = assignment.variantId;

      return acc;
    }, {});

    return { ...experimentsList, ...experimentAssignments };
  } catch (error) {
    return {};
  }
};
