import { rollbar, RollbarLogArgument } from "../../../../utils/rollbar";
import { hashString } from "../../domain/view-models/experiment-view-model";
import { PartialWindow } from "../../types";
import { isLocalStorageAvailable } from "../../utils/browser-features";

export type StoredExperiment = {
  experimentId: string;
  variantId: string;
};

export type ExperimentStorage = {
  userHash?: string;
  assignments: Record<string, StoredExperiment>;
};

type AdapterOptions = {
  window?: PartialWindow & { localStorage?: Storage };
  storageKey?: string;
};

export type ExperimentStorageResponse = {
  storeAssignment: (experimentId: string, variantId: string) => void;
  getAssignment: (experimentId: string) => StoredExperiment | null;
  getAllAssignments: () => Record<string, StoredExperiment>;
  storeUserHash: (userKey: string, countryCodeIso: string) => void;
  getUserHash: () => string | undefined;
  clearAll: () => void;
};

const isWindowDefined = typeof window !== "undefined";

export const createExperimentStorageAdapter = ({
  window: windowObj = isWindowDefined
    ? (window as PartialWindow & { localStorage?: Storage })
    : undefined,
  storageKey = "ab_test_assignments",
}: AdapterOptions = {}): ExperimentStorageResponse => {
  const hasLocalStorage = isLocalStorageAvailable();

  const getStorage = (): ExperimentStorage => {
    try {
      if (!hasLocalStorage) return { assignments: {} };

      const storedValue = windowObj?.localStorage?.getItem(storageKey);
      if (!storedValue) return { assignments: {} };

      return JSON.parse(storedValue) as ExperimentStorage;
    } catch (error) {
      rollbar.error("Failed to retrieve experiment storage", error as RollbarLogArgument);

      return { assignments: {} };
    }
  };

  const saveStorage = (storage: ExperimentStorage): void => {
    try {
      if (!hasLocalStorage) return;
      windowObj?.localStorage?.setItem(storageKey, JSON.stringify(storage));
    } catch (error) {
      rollbar.error("Failed to save experiment storage", error as RollbarLogArgument);
    }
  };

  return {
    storeAssignment: (experimentId: string, variantId: string): void => {
      try {
        if (!hasLocalStorage) return;

        const storage = getStorage();
        const updatedStorage: ExperimentStorage = {
          ...storage,
          assignments: {
            ...storage.assignments,
            [experimentId]: {
              experimentId,
              variantId,
            },
          },
        };

        saveStorage(updatedStorage);
      } catch (error) {
        rollbar.error("Failed to store experiment assignment", error as RollbarLogArgument);
      }
    },

    storeUserHash: (userKey: string, countryCodeIso: string): void => {
      try {
        if (!hasLocalStorage) return;

        const storage = getStorage();
        const userHash = hashString(`user-${userKey}-${countryCodeIso}`).toString();

        const updatedStorage: ExperimentStorage = {
          ...storage,
          userHash,
        };

        saveStorage(updatedStorage);
      } catch (error) {
        rollbar.error("Failed to store user hash", error as RollbarLogArgument);
      }
    },

    getUserHash: (): string | undefined => {
      try {
        if (!hasLocalStorage) return undefined;

        const storage = getStorage();

        return storage.userHash;
      } catch (error) {
        rollbar.error("Failed to get user hash", error as RollbarLogArgument);

        return undefined;
      }
    },

    getAssignment: (experimentId: string): StoredExperiment | null => {
      if (!hasLocalStorage) return null;

      const storage = getStorage();

      return Object.prototype.hasOwnProperty.call(storage.assignments, experimentId)
        ? storage.assignments[experimentId]
        : null;
    },

    getAllAssignments: (): Record<string, StoredExperiment> => {
      if (!hasLocalStorage) return {};

      return getStorage().assignments;
    },

    clearAll: (): void => {
      try {
        if (!hasLocalStorage) return;
        windowObj?.localStorage?.removeItem(storageKey);
      } catch (error) {
        rollbar.error("Failed to clear experiment storage", error as RollbarLogArgument);
      }
    },
  };
};
