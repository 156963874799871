import { AVAILABLE_COUNTRIES, DEFAULT_COUNTRY } from "../constants";

export type Locale = {
  code: string;
  name: string;
  nativeName: string;
};

export type Country = {
  name: string;
  language: Locale;
  prefixPhoneNumber: string;
  currency: string;
  iso: string;
};

export const CountrySource = {
  DEFAULT: "default",
  RESULT: "result",
  URL: "url",
} as const;

export type CountrySourceType = (typeof CountrySource)[keyof typeof CountrySource];

export type CountryDetectionResponse = {
  locale: string;
  source: CountrySourceType;
};

type CountryViewModel = {
  country: Country;
  source: CountrySourceType;
};

export const createCountryViewModel = (detection?: CountryDetectionResponse): CountryViewModel => {
  if (!detection) {
    return {
      country: DEFAULT_COUNTRY,
      source: CountrySource.DEFAULT,
    };
  }

  const [language, country] = detection.locale.split("-");
  const normalizedLocale = country ? `${language}-${country.toUpperCase()}` : language;

  const detectedCountry = Object.values(AVAILABLE_COUNTRIES).find(
    (c) => c.language.code === normalizedLocale
  );

  return {
    country: detectedCountry ?? DEFAULT_COUNTRY,
    source: detection.source,
  };
};
