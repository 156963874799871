import { create } from "zustand";
import { devtools } from "zustand/middleware";

import { createExperimentStorageAdapter } from "../adapters/experiment-storage-adapter";

export type ExperimentAssignment = {
  experimentId: string;
  variantId: string;
};

type ExperimentState = {
  assignments: Record<string, ExperimentAssignment>;
  saveAssignment: (experimentId: string, variantId: string) => void;
  storeUserHash: (userKey: string, countryCodeIso: string) => void;
  getUserHash: () => string | undefined;
  clearAll: () => void;
};

export const useExperimentStore = create<ExperimentState>()(
  devtools(
    (set) => {
      const storageAdapter = createExperimentStorageAdapter();
      const storedAssignments = storageAdapter.getAllAssignments();

      return {
        assignments: storedAssignments,
        saveAssignment: (experimentId: string, variantId: string) => {
          storageAdapter.storeAssignment(experimentId, variantId);
          set(
            (state) => ({
              assignments: {
                ...state.assignments,
                [experimentId]: {
                  experimentId,
                  variantId,
                },
              },
            }),
            false,
            `saveAssignment/${experimentId}`
          );
        },
        storeUserHash: (userKey: string, countryCodeIso: string) => {
          storageAdapter.storeUserHash(userKey, countryCodeIso);
        },
        getUserHash: () => {
          return storageAdapter.getUserHash();
        },
        clearAll: () => {
          storageAdapter.clearAll();
          set(
            () => ({
              assignments: {},
            }),
            false,
            "clearAll"
          );
        },
      };
    },
    {
      name: "Experiment Store",
      enabled: process.env.NODE_ENV === "development",
    }
  )
);
