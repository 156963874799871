import { PageId } from "./pages";

export enum CountryCode {
  DE = "DE",
  ES = "ES",
  FR = "FR",
  IT = "IT",
  PT = "PT",
  NL = "NL",
  PL = "PL",
  AT = "AT",
  NO = "NO",
  FI = "FI",
  IE = "IE",
  GR = "GR",
  HU = "HU",
  RO = "RO",
  CZ = "CZ",
}

export type Locale = "de" | "es" | "fr" | "it" | "pt";
export const availableLocales: Locale[] = ["de", "es", "fr", "it", "pt"];

export interface ContactMethodElement {
  destination: string;
  messageToShow?: string;
  phoneNumberToShow?: string;
  type: string;
}

export interface ZipCodeValidation {
  min: number;
  max: number;
}

export interface PhoneNumberLengthValidation {
  min: number;
  max: number;
}

export interface CountrySubsetConfiguration {
  countryCode: CountryCode;
  defaultLocale: Locale | string;
  name: string;
}

export interface CountryConfiguration extends CountrySubsetConfiguration {
  allowChangePolicyStartDate: boolean;
  alternativeOnboardingEmailTitle: boolean;
  becasDiscount: boolean;
  chatLink: boolean;
  contactLinks: boolean;
  contactMethods: ContactMethodElement[];
  euFederLogos: boolean;
  exceptionStartingNumbersForWhatsApp: string[];
  fakeChipNumber: boolean;
  glAddon: boolean;
  hasComplianceErrorMessage: boolean;
  hasDescriptionInAccessPage: boolean;
  hasTattooAnswer: boolean;
  hasWhatsAppConfirmationCheckbox: boolean;
  idDocumentRequired: boolean;
  invalidStartingNumbersForWhatsApp: string[];
  listTaxes: boolean;
  multiplePetsDiscount: number;
  pagesWithChatLink: PageId[];
  paymentMethods: string[];
  phoneNumberBeforePetParentPage: boolean;
  phoneNumberInPreQuotes: boolean;
  phoneNumberLength: PhoneNumberLengthValidation;
  phoneNumberPrefix: string;
  preContractNotificationIsRequired: boolean;
  sanitizePhoneNumber: boolean;
  scheduleCallOption: boolean;
  selectableProductsDisclaimer: boolean;
  smallPrintPages: boolean;
  zipCode: ZipCodeValidation;
}

type CountryOverrides = Partial<Omit<CountryConfiguration, keyof CountrySubsetConfiguration>>;

const defaultCountryConfiguration: Omit<
  CountryConfiguration,
  "countryCode" | "defaultLocale" | "name"
> = {
  allowChangePolicyStartDate: true,
  alternativeOnboardingEmailTitle: false,
  becasDiscount: true,
  chatLink: false,
  contactLinks: true,
  contactMethods: [],
  euFederLogos: false,
  exceptionStartingNumbersForWhatsApp: [],
  fakeChipNumber: true,
  glAddon: false,
  hasComplianceErrorMessage: false,
  hasDescriptionInAccessPage: false,
  hasTattooAnswer: false,
  hasWhatsAppConfirmationCheckbox: false,
  idDocumentRequired: false,
  invalidStartingNumbersForWhatsApp: [],
  listTaxes: false,
  multiplePetsDiscount: 25,
  pagesWithChatLink: [],
  paymentMethods: ["card"],
  phoneNumberBeforePetParentPage: false,
  phoneNumberInPreQuotes: true,
  phoneNumberLength: { min: 9, max: 9 },
  phoneNumberPrefix: "",
  preContractNotificationIsRequired: false,
  sanitizePhoneNumber: true,
  scheduleCallOption: false,
  selectableProductsDisclaimer: false,
  smallPrintPages: false,
  zipCode: { min: 5, max: 5 },
};

const spainConfiguration: CountryOverrides = {
  allowChangePolicyStartDate: false,
  contactLinks: false,
  contactMethods: [
    {
      destination: "https://wa.me/message/Q5KWYO5LVFTYK1?utm_term=maincta",
      type: "whatsapp",
    },
  ],
  euFederLogos: true,
  fakeChipNumber: false,
  glAddon: true,
  hasComplianceErrorMessage: true,
  idDocumentRequired: true,
  pagesWithChatLink: [
    PageId.uncovered,
    PageId.quotes,
    PageId.hasChip,
    PageId.noChip,
    PageId.petParent,
    PageId.address,
    PageId.access,
    PageId.chip,
    PageId.petFamily,
    PageId.smallPrint,
    PageId.preExistencesConditions,
    PageId.waitingPeriod,
    PageId.details,
    PageId.landing,
  ],
  phoneNumberBeforePetParentPage: true,
  phoneNumberInPreQuotes: false,
  smallPrintPages: true,
  sanitizePhoneNumber: false,
  selectableProductsDisclaimer: true,
};

function createCountryConfiguration(
  subsetConfig: CountrySubsetConfiguration,
  overrides: CountryOverrides
): CountryConfiguration {
  return {
    ...defaultCountryConfiguration,
    ...overrides,
    ...subsetConfig,
  };
}

function createSpainCountryConfig(
  countryCode: CountryCode,
  defaultLocale: Locale | string,
  name: string,
  phonePrefix: string,
  customOverrides: CountryOverrides = {}
): CountryConfiguration {
  return createCountryConfiguration(
    {
      countryCode,
      defaultLocale,
      name,
    },
    {
      ...spainConfiguration,
      phoneNumberPrefix: phonePrefix,
      ...customOverrides,
    }
  );
}

export const countries: Record<CountryCode, CountryConfiguration> = {
  DE: createCountryConfiguration(
    {
      countryCode: CountryCode.DE,
      defaultLocale: "de",
      name: "Deutschland",
    },
    {
      alternativeOnboardingEmailTitle: true,
      chatLink: true,
      contactMethods: [
        {
          destination: "https://wa.me/message/AL336C7QS7K4G1?utm_term=maincta",
          type: "whatsapp",
          messageToShow: "common.whatsapp_contact.messageToShow",
        },
      ],
      exceptionStartingNumbersForWhatsApp: ["15", "16", "17"],
      hasTattooAnswer: true,
      hasWhatsAppConfirmationCheckbox: true,
      invalidStartingNumbersForWhatsApp: ["1", "2", "3"],
      listTaxes: true,
      multiplePetsDiscount: 50,
      pagesWithChatLink: [
        PageId.uncovered,
        PageId.quotes,
        PageId.hasChip,
        PageId.noChip,
        PageId.petParent,
        PageId.address,
        PageId.access,
        PageId.chip,
        PageId.petFamily,
        PageId.details,
        PageId.landing,
      ],
      paymentMethods: ["card", "sepa_debit", "paypal"],
      phoneNumberLength: { min: 8, max: 12 },
      phoneNumberPrefix: "49",
      scheduleCallOption: true,
    }
  ),
  FR: createCountryConfiguration(
    {
      countryCode: CountryCode.FR,
      defaultLocale: "fr",
      name: "France",
    },
    {
      contactMethods: [
        {
          destination: "https://wa.me/message/UC7OSSJPSYO3L1?utm_term=maincta",
          type: "whatsapp",
          messageToShow: "common.whatsapp_contact.messageToShow",
        },
      ],
      hasComplianceErrorMessage: true,
      hasDescriptionInAccessPage: true,
      hasWhatsAppConfirmationCheckbox: true,
      invalidStartingNumbersForWhatsApp: ["1", "2", "3", "4", "5"],
      pagesWithChatLink: [
        PageId.uncovered,
        PageId.quotes,
        PageId.hasChip,
        PageId.noChip,
        PageId.petParent,
        PageId.address,
        PageId.access,
        PageId.chip,
        PageId.petFamily,
        PageId.details,
      ],
      phoneNumberLength: { min: 9, max: 10 },
      phoneNumberPrefix: "33",
      preContractNotificationIsRequired: true,
      scheduleCallOption: true,
    }
  ),
  IT: createCountryConfiguration(
    {
      countryCode: CountryCode.IT,
      defaultLocale: "it",
      name: "Italia",
    },
    {
      contactMethods: [
        {
          destination: "tel:+39800829683",
          type: "phone",
          phoneNumberToShow: "800 829 683",
        },
        {
          destination: "https://wa.me/message/5CBJS2TOGOLWA1?utm_term=maincta",
          type: "whatsapp",
        },
      ],
      pagesWithChatLink: [
        PageId.uncovered,
        PageId.quotes,
        PageId.hasChip,
        PageId.noChip,
        PageId.petParent,
        PageId.address,
        PageId.access,
        PageId.chip,
        PageId.petFamily,
        PageId.details,
      ],
      phoneNumberLength: { min: 6, max: 12 },
      phoneNumberPrefix: "39",
      sanitizePhoneNumber: false,
    }
  ),
  PT: createCountryConfiguration(
    {
      countryCode: CountryCode.PT,
      defaultLocale: "pt",
      name: "Portugal",
    },
    {
      contactMethods: [
        {
          destination: "https://wa.me/message/YE6MEOBRSFTYB1?utm_term=maincta",
          type: "whatsapp",
          messageToShow: "common.whatsapp_contact.messageToShow",
        },
      ],
      pagesWithChatLink: [
        PageId.uncovered,
        PageId.quotes,
        PageId.hasChip,
        PageId.noChip,
        PageId.petParent,
        PageId.address,
        PageId.access,
        PageId.chip,
        PageId.petFamily,
        PageId.details,
      ],
      phoneNumberPrefix: "351",
      zipCode: { min: 8, max: 8 },
      sanitizePhoneNumber: false,
    }
  ),
  ES: createCountryConfiguration(
    {
      countryCode: CountryCode.ES,
      defaultLocale: "es",
      name: "España",
    },
    spainConfiguration
  ),
  NL: createSpainCountryConfig(CountryCode.NL, "nl-NL", "Netherlands", "31"),
  PL: createSpainCountryConfig(CountryCode.PL, "pl-PL", "Poland", "48"),
  AT: createSpainCountryConfig(CountryCode.AT, "de-AT", "Austria", "43"),
  NO: createSpainCountryConfig(CountryCode.NO, "nb-NO", "Norway", "47"),
  FI: createSpainCountryConfig(CountryCode.FI, "fi-FI", "Finland", "358"),
  IE: createSpainCountryConfig(CountryCode.IE, "en-IE", "Ireland", "353"),
  GR: createSpainCountryConfig(CountryCode.GR, "el-GR", "Greece", "30"),
  HU: createSpainCountryConfig(CountryCode.HU, "hu-HU", "Hungary", "36"),
  RO: createSpainCountryConfig(CountryCode.RO, "ro-RO", "Romania", "40"),
  CZ: createSpainCountryConfig(CountryCode.CZ, "cs-CZ", "Czech Republic", "420"),
};
