import { Experiment } from "../view-models";

export const EXPERIMENT_IDS = {
  HEALTH_UNLIMITED_VISIBILITY: "health-unlimited-visibility",
} as const;

export const EXPERIMENTS: Experiment[] = [
  {
    id: EXPERIMENT_IDS.HEALTH_UNLIMITED_VISIBILITY,
    name: "Health Unlimited Visibility",
    variants: [
      { id: "visible", name: "Health Unlimited Visible", weight: 0.5 },
      { id: "hidden", name: "Health Unlimited Hidden", weight: 0.5 },
    ],
    countries: ["ES"],
  },
];
