import { CountryCode } from "../../../../settings/countries";
import { ProductId } from "../../../../settings/products";

type PetBreed = {
  kbKey: string;
  name: string;
};

type BarkibuCard = {
  shippingAddress: string | null;
  shippingCity: string | null;
  shippingZipcode: string | null;
  issued: boolean;
};

type TrackingParams = {
  metaFbc: string | null;
  metaFbp: string | null;
};

type Product = {
  id: ProductId;
  name: string;
};

type Quote = {
  premium: number;
  product: Product;
  selected: boolean | null;
  uuid: string;
};

export type PetSpecies = "cat" | "dog";
export type PetSex = "female" | "male";
export type ResultStatus = "binding" | "completed" | "created" | "requested";

type PetInfo = {
  name: string;
  chip: string | null;
  species: PetSpecies;
  birthDate: string;
  sex: PetSex;
  breed: PetBreed;
  secondBreed: PetBreed | null;
  purchasedProducts: string[];
};

type PetParentInfo = {
  firstName: string | null;
  lastName: string | null;
  idNumber: string | null;
  address: string | null;
  city: string | null;
  zip: string | null;
  phone: string | null;
  kbKey: string;
  countryCode: string;
  salutation: string | null;
  birthDate: string | null;
  hasFriend: boolean | null;
};

type PolicyInfo = {
  startDate: string;
  signatureUrl: string | null;
  paymentIntervalMonths: number;
};

export type ResultViewModel = {
  uuid: string;
  email: string;
  status: ResultStatus;
  pet: PetInfo;
  petParent: PetParentInfo;
  policy: PolicyInfo;
  barkibuCard: BarkibuCard;
  trackingParams: TrackingParams;
  country: CountryCode;
  currency: string;
  quotes: Quote[];
};

export type ResultDTO = {
  uuid: string;
  email: string;
  pet_name: string;
  pet_chip: string | null;
  pet_species: PetSpecies;
  pet_parent_first_name: string | null;
  pet_parent_last_name: string | null;
  pet_parent_id_number: string | null;
  pet_parent_address: string | null;
  pet_parent_city: string | null;
  pet_parent_zip: string | null;
  pet_parent_phone: string | null;
  pet_parent_kb_key: string;
  pet_parent_country_code: string;
  pet_parent_salutation: string | null;
  pet_parent_birth_date: string | null;
  pet_birth_date: string;
  pet_sex: PetSex;
  pet_breed: PetBreed;
  policy_start_date: string;
  currency: string;
  signature_url: string | null;
  payment_interval_months: number;
  status: ResultStatus;
  pet_parent_has_friend: boolean | null;
  pet_purchased_products: string[];
  pet_second_breed: PetBreed | null;
  barkibu_card: BarkibuCard;
  tracking_params: TrackingParams;
  country: CountryCode;
  quotes: Quote[];
};

export const createResultViewModel = (data: ResultDTO): ResultViewModel => {
  return {
    uuid: data.uuid,
    email: data.email,
    status: data.status,
    pet: {
      name: data.pet_name,
      chip: data.pet_chip,
      species: data.pet_species,
      birthDate: data.pet_birth_date,
      sex: data.pet_sex,
      breed: data.pet_breed,
      secondBreed: data.pet_second_breed,
      purchasedProducts: data.pet_purchased_products,
    },
    petParent: {
      firstName: data.pet_parent_first_name,
      lastName: data.pet_parent_last_name,
      idNumber: data.pet_parent_id_number,
      address: data.pet_parent_address,
      city: data.pet_parent_city,
      zip: data.pet_parent_zip,
      phone: data.pet_parent_phone,
      kbKey: data.pet_parent_kb_key,
      countryCode: data.pet_parent_country_code,
      salutation: data.pet_parent_salutation,
      birthDate: data.pet_parent_birth_date,
      hasFriend: data.pet_parent_has_friend,
    },
    policy: {
      startDate: data.policy_start_date,
      signatureUrl: data.signature_url,
      paymentIntervalMonths: data.payment_interval_months,
    },
    currency: data.currency,
    barkibuCard: data.barkibu_card,
    trackingParams: data.tracking_params,
    country: data.country,
    quotes: data.quotes,
  };
};
