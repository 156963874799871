import { PartialWindow } from "../types";

export const getUrlLocale = (windowObj: PartialWindow): string => {
  const pathSegments = windowObj.location.pathname
    .split("/")
    .filter((segment) => segment.length > 0);

  return pathSegments[0] || "";
};

export const getBrowserLocale = (windowObj: PartialWindow): string => {
  return windowObj.navigator.language || windowObj.navigator.languages[0] || "";
};

export const isResultUrl = (windowObj: PartialWindow): boolean => {
  const pathSegments = windowObj.location.pathname
    .split("/")
    .filter((segment) => segment.length > 0);

  return pathSegments.length >= 2 && pathSegments[0] === "results";
};

export const getResultUuidFromUrl = (windowObj: PartialWindow): string | null => {
  const pathSegments = windowObj.location.pathname
    .split("/")
    .filter((segment) => segment.length > 0);

  if (pathSegments.length >= 2 && pathSegments[0] === "results") {
    return pathSegments[1];
  }

  return null;
};

export const getUrlCountryParam = (windowObj: PartialWindow): string | null => {
  try {
    if (!windowObj.location.href) return null;
    const url = new URL(windowObj.location.href);

    return url.searchParams.get("country");
  } catch {
    return null;
  }
};

export const isLeadsNewPath = (windowObj: PartialWindow): boolean => {
  const pathSegments = windowObj.location.pathname
    .split("/")
    .filter((segment) => segment.length > 0);

  return pathSegments.length >= 2 && pathSegments[0] === "leads" && pathSegments[1] === "new";
};

export const getLeadsNewCountryParam = (windowObj: PartialWindow): string | null => {
  if (!isLeadsNewPath(windowObj)) return null;

  return getUrlCountryParam(windowObj);
};

export const isLocalStorageAvailable = (): boolean => {
  try {
    const testKey = "__test_storage__";
    window.localStorage.setItem(testKey, testKey);
    window.localStorage.removeItem(testKey);

    return true;
  } catch (e) {
    return false;
  }
};
