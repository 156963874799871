import { rollbar, RollbarLogArgument } from "../../../../utils/rollbar";
import { useExperimentStore } from "../../data/repositories";
import { EXPERIMENTS } from "../../domain/constants";
import { Country } from "../../domain/view-models/country-view-model";
import {
  hashString,
  isExperimentEligible,
  selectVariant,
} from "../../domain/view-models/experiment-view-model";

export type InitializeExperimentsOptions = {
  country: Country;
  leadKbKey?: string;
};

export const verifyExperimentOwnership = (leadKbKey: string, countryCodeIso: string): boolean => {
  try {
    const experimentStore = useExperimentStore.getState();
    const storedUserHash = experimentStore.getUserHash();

    if (!storedUserHash) return false;

    const expectedUserHash = hashString(`user-${leadKbKey}-${countryCodeIso}`).toString();

    return storedUserHash === expectedUserHash;
  } catch (error) {
    rollbar.error("Failed to verify experiment ownership", error as RollbarLogArgument);

    return false;
  }
};

export const initializeExperiments = ({
  country,
  leadKbKey,
}: InitializeExperimentsOptions): void => {
  try {
    const {
      assignments = {},
      clearAll,
      storeUserHash,
      saveAssignment,
    } = useExperimentStore.getState();
    let hasExistingAssignments = false;
    try {
      hasExistingAssignments = Object.keys(assignments).length > 0;
    } catch (error) {
      rollbar.info("Failed to get experiment assignments", error as RollbarLogArgument);
    }
    const idForHashing = leadKbKey ?? Math.random().toString(36).substring(2, 15);

    if (hasExistingAssignments) {
      const ownershipValid = verifyExperimentOwnership(idForHashing, country.iso);

      if (ownershipValid) return;

      clearAll();
    }

    storeUserHash(idForHashing, country.iso);

    EXPERIMENTS.forEach((experiment) => {
      if (!isExperimentEligible(experiment, country)) return;

      const hash = hashString(`${idForHashing}-${experiment.id}`);
      const selectedVariant = selectVariant(experiment, hash);

      saveAssignment(experiment.id, selectedVariant.id);
    });
  } catch (error) {
    rollbar.error(`Failed to initialize experiments`, error as RollbarLogArgument);
  }
};
