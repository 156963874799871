import { Country } from "../view-models";
import { AVAILABLE_LOCALES } from "./locales";

export const AVAILABLE_COUNTRIES: Record<string, Country> = {
  ES: {
    name: "Spain",
    language: AVAILABLE_LOCALES.es,
    prefixPhoneNumber: "34",
    currency: "EUR",
    iso: "ES",
  },
  DE: {
    name: "Germany",
    language: AVAILABLE_LOCALES.de,
    prefixPhoneNumber: "49",
    currency: "EUR",
    iso: "DE",
  },
  FR: {
    name: "France",
    language: AVAILABLE_LOCALES.fr,
    prefixPhoneNumber: "33",
    currency: "EUR",
    iso: "FR",
  },
  IT: {
    name: "Italy",
    language: AVAILABLE_LOCALES.it,
    prefixPhoneNumber: "39",
    currency: "EUR",
    iso: "IT",
  },
  PT: {
    name: "Portugal",
    language: AVAILABLE_LOCALES.pt,
    prefixPhoneNumber: "351",
    currency: "EUR",
    iso: "PT",
  },
  NL: {
    name: "Netherlands",
    language: AVAILABLE_LOCALES.nl_NL,
    prefixPhoneNumber: "31",
    currency: "EUR",
    iso: "NL",
  },
  PL: {
    name: "Poland",
    language: AVAILABLE_LOCALES.pl_PL,
    prefixPhoneNumber: "48",
    currency: "PLN",
    iso: "PL",
  },
  AT: {
    name: "Austria",
    language: AVAILABLE_LOCALES.de_AT,
    prefixPhoneNumber: "43",
    currency: "EUR",
    iso: "AT",
  },
  NO: {
    name: "Norway",
    language: AVAILABLE_LOCALES.nb_NO,
    prefixPhoneNumber: "47",
    currency: "NOK",
    iso: "NO",
  },
  FI: {
    name: "Finland",
    language: AVAILABLE_LOCALES.fi_FI,
    prefixPhoneNumber: "358",
    currency: "EUR",
    iso: "FI",
  },
  IE: {
    name: "Ireland",
    language: AVAILABLE_LOCALES.en_IE,
    prefixPhoneNumber: "353",
    currency: "EUR",
    iso: "IE",
  },
  GR: {
    name: "Greece",
    language: AVAILABLE_LOCALES.el_GR,
    prefixPhoneNumber: "30",
    currency: "EUR",
    iso: "GR",
  },
  HU: {
    name: "Hungary",
    language: AVAILABLE_LOCALES.hu_HU,
    prefixPhoneNumber: "36",
    currency: "HUF",
    iso: "HU",
  },
  RO: {
    name: "Romania",
    language: AVAILABLE_LOCALES.ro_RO,
    prefixPhoneNumber: "40",
    currency: "RON",
    iso: "RO",
  },
  CZ: {
    name: "Czech Republic",
    language: AVAILABLE_LOCALES.cs_CZ,
    prefixPhoneNumber: "420",
    currency: "CZK",
    iso: "CZ",
  },
};
export const DEFAULT_COUNTRY = AVAILABLE_COUNTRIES.ES;
