export interface ProductBenefitsViewModel {
  benefits: Array<string>;
}

export const extractBenefits = (
  translations: Record<string, string>,
  keyPrefix: string
): Array<string> => {
  return Object.entries(translations)
    .filter(([key]) => key.startsWith(keyPrefix))
    .reduce((acc, [key, value]) => {
      const itemNumber = key.match(/item_(\d+)/)?.[1];

      if (itemNumber && key.endsWith(".title")) {
        const index = parseInt(itemNumber, 10) - 1;
        acc[index] = value;
      }

      return acc;
    }, [] as Array<string>);
};

export const createHealthBenefitsViewModel = (
  translations: Record<string, string>
): ProductBenefitsViewModel => {
  return {
    benefits: extractBenefits(translations, "product_benefits.accidents_or_illness.item_"),
  };
};

export const createPreventionBenefitsViewModel = (
  translations: Record<string, string>,
  species: string
): ProductBenefitsViewModel => {
  return {
    benefits: extractBenefits(translations, `product_benefits.${species}.prevention_care.item_`),
  };
};

export const createGlBenefitsViewModel = (
  translations: Record<string, string>
): ProductBenefitsViewModel => {
  return {
    benefits: extractBenefits(translations, "product_benefits.general_liability.item_"),
  };
};
